body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
  .App {
    text-align: center;
  }
  
  .App-header {
    background-color: #282c34;
    padding: 20px;
    color: white;
  }
  
  main {
    padding: 20px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    background: #fff;
    margin: 8px 0;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  